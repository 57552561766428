/*  ========================================
  GRAPH STYLES
==========================================*/

.grid path {
  stroke-width: 0;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.4;
  shape-rendering: crispEdges;
}

.x-axis,
.y-axis {
  font-size: 12px;
}

.x-axis .domain,
.x-axis .tick line,
.y-axis .domain,
.y-axis .tick line {
  stroke: lightgrey;
}


.line {
  fill: none;
  /* stroke: steelblue; */
  stroke-width: 2.5px;
}

.pumping-vs-drawdown-graph .area {
  opacity: 0.15
}

.tooltip-pumping-vs-drawdown {
  background-color: #333333;
  padding: 10px;
  border-radius: 4px;
  color: #ffffff;
  position: absolute;
  z-index: 1000;
}

.tooltip-legend {
  width: 30px;
  height: 10px;
  display: inline-block;
}